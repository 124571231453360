/* globals MantleEcom */

Drupal.behaviors.gnavCart = (function ($, _, site, generic) {
  'use strict';

  // Private variables:
  var $blocks = $();
  var $trigger = $();
  var $counters = $();
  var data = {
    item_count: 0,
    subtotal: '',
    points: 0,
    order_within: '',
    ship_sooner_date: '',
    items: [],
    singular: 0,
    new_items: []
  };
  var state = 'empty';
  var loaded = false;
  var closeNav = false;
  var closeTimeout;
  var _normalizeResponseKeys = MantleEcom.normalizeResponseKeys;

  function _clearCloseTimeout() {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
  }

  function _setCloseTimeout() {
    if (state !== 'added') {
      return;
    }

    // Clear old timeout.
    _clearCloseTimeout();

    closeTimeout = setTimeout(function () {
      if (closeNav) {
        // Drupal.behaviors.gnav.close();
        // ghetto, but gets the job done
        $('.js-cart-block-container .js-gnav-util-close').trigger('click');
      }
      behavior.setState();
    }, 5000);
  }

  // Public methods:
  var behavior = {
    attach: function (context) {
      $trigger = $trigger.add($('.gnav-util--cart', context)).first();
      $blocks = $blocks.add($('.js-gnav-util__content__inner--cart', context));
      $counters = $counters.add($('.gnav-util__icon__cart-count', context));
      // Get the initial item count from the cookie to avoid unnecessary trips
      // to the server.
      if (!data.item_count) {
        this.setData({ item_count: site.userInfoCookie.getValue('item_count') - 0 });
      }
    },

    render: function () {
      // Confirm document is ready before rendering template to prevent errors.
      $(function () {
        var rendered = site.template.get({
          name: 'gnav_cart_content',
          data: data
        });

        // Some of the field values may actually contain mustache themselves, so
        // this template needs to be run through rendering a second time.
        rendered = site.template.render(rendered, data);

        $blocks.html(rendered);

        // Update the counters that are outside of the template
        $counters.text(data.item_count > 0 ? data.item_count : '');
      });

      return this;
    },

    load: function (force) {
      if (loaded && (!_.isBoolean(force) || !force)) {
        return this;
      }

      $blocks.addClass('loading');

      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            trans_fields: ['TRANS_ID', 'totals'],
            payment_fields: [],
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          $blocks.removeClass('loading');

          var value = response.getValue();

          if (_.isUndefined(value) || !value) {
            return;
          }

          var cartItems = value.order.items.concat(value.order.samples);

          behavior.setData({
            subtotal: value.formattedSubtotal,
            points: value.points,
            order_within: '', // This was removed from the designs
            ship_sooner_date: '', // This was removed from the designs
            item_count: value.items_count,
            items: _normalizeResponseKeys(cartItems)
          });
        },
        onError: function () {
          $blocks.removeClass('loading');
          // @TODO: a failure message should go here.
          loaded = false;
        }
      });

      // Don't put loaded in success function! That allows the user to fire
      // additonal requests while the first is still loading.
      loaded = true;

      return this;
    },

    // eslint-disable-next-line
    addItem: function (result) {
      if (
        _.isUndefined(result) ||
        !result ||
        _.isUndefined(result.trans_data) ||
        _.isUndefined(result.ac_results)
      ) {
        return this;
      }

      var addedItems = MantleEcom.getAddedItems(result);
      var allItems = result.trans_data.order.items;

      MantleEcom.setCollectionData(allItems);

      var points = result.trans_data.points === 0 ? 0 : result.trans_data.points || data.points;

      this.setData({
        subtotal: result.trans_data.formattedSubtotal,
        points: points,
        items: _normalizeResponseKeys(result.trans_data.order.items),
        item_count: result.trans_data.items_count,
        new_items: _normalizeResponseKeys(addedItems)
      });

      // Temporarily set the added state:
      this.setState('added');

      closeNav = true;
      // The response after you added to bag contains trans_data.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    // eslint-disable-next-line complexity
    addOffer: function (result) {
      if (
        _.isUndefined(result) ||
        !result ||
        _.isUndefined(result.trans) ||
        _.isUndefined(result.items)
      ) {
        return this;
      }
      // var resultType = this.getResultType(result.ac_results);

      // var addedItems = '';
      // addedItems = _.map(result.ac_results, function(value) {
      //   var item = result.items;

      //   // Seems very dumb to calculate this on the front end.
      //   item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

      //   return item;
      // });

      this.setData({
        subtotal: result.trans.formattedSubtotal,
        points: result.trans.points === 0 ? 0 : result.trans.points || data.points,
        items: _normalizeResponseKeys(result.trans.order.items),
        item_count: result.trans.items_count,
        new_items: _normalizeResponseKeys(result.trans.order.samples)
      });

      // Temporarily set the added state:
      this.setState('added');
      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      closeNav = true;
      // The response after you added to bag contains trans.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    // Setters:
    setState: function (newState) {
      var states = ['empty', 'nonempty', 'added'];
      var classPrefix = 'cart-block--';
      var stateClasses = classPrefix + states.join(' ' + classPrefix);

      // If state is undefined, figure it out:
      if (_.isUndefined(newState)) {
        state = data.item_count > 0 ? 'nonempty' : 'empty';
        // Sanity check:
      } else if (!_.includes(states, newState)) {
        throw new Error('"' + newState + '" is not a valid cart state.');
      } else {
        state = newState;
      }

      $blocks.removeClass(stateClasses).addClass(classPrefix + state);
      $trigger.toggleClass('gnav-util--cart--nonempty', state !== 'empty');

      return this;
    },

    setData: function (newData) {
      _.extend(data, newData);
      data.singular = data.item_count === 1;

      this.setState().render();

      return this;
    },

    // Getters:
    //
    // Find the collection that's being added to cart (for addItem)
    getAddedCollection: function (results) {
      return MantleEcom.getAddedCollection(results);
    },
    getState: function () {
      return state;
    },

    getData: function (key) {
      return _.isUndefined(key) ? data : data[key];
    },

    getResultType: function (results) {
      return MantleEcom.getResultType(results);
    }
  };

  // Document listeners:
  $(document).on('offerToCart.success', function (event, result) {
    $('.js-cart-block-container').removeClass('hidden').show();
    behavior.addOffer(result);
  });

  // Document listeners:
  $(document).on('addToCart.success', function (event, result) {
    // @setup new brand - this is temporary open/close for testing add to cart
    // a more integrated method with all gnav should be done per brand
    $('.js-cart-block-container').removeClass('hidden').show();
    behavior.addItem(result);
  });

  $(document).on('loadCart.success', function (event, force) {
    behavior.load(true);
  });

  // Override preventDefault on gnav overlay logic if cart is empty:
  // $(document).on('click', '.js-gnav-util-trigger--cart', function() {
  //   if (state === 'empty') {
  //     window.location = $(this).attr('href');
  //     return true;
  //   }
  // });

  $(document).on('mouseenter', '.js-cart-block-container', _clearCloseTimeout);
  $(document).on('mouseleave', '.js-cart-block-container', _setCloseTimeout);

  $(document).on('click', '.cart-block__items__view-bag', function (event) {
    event.preventDefault();

    _clearCloseTimeout();
    behavior.setState().render();
  });

  $(document).on('mouseenter', '.js-gnav-util-trigger--cart', function (event) {
    event.preventDefault();
    if ($blocks.hasClass('loading')) {
      return;
    }
    if ($('.gnav-util--cart').length) {
      if (data.item_count === 0) {
        return;
      }
      behavior.load(true);
    }
  });

  return behavior;
})(
  (window.jQuery = window.jQuery || function () {}),
  (window._ = window._ || {}),
  (window.site = window.site || {}),
  (window.generic = window.generic || {})
);
